<template>
    <modal ref="modalLeecherosCodigo" titulo="Vendedores que usan el código" no-aceptar no-cancelar>
        <div class="row mx-0 align-items-center justify-center">
            <div class="col-auto">
                <div class="bg-light-f5 border br-10 px-3 py-1 text-general">
                    <span v-if="identificadores.length>1">{{identificadores[0].identificador}}</span>
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-light-f5 border br-10 px-3 py-1 text-general">
                    {{identificadores.length}} <i class="icon-leechero-cap text-general f-18" />
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-center py-3">
            <div class="col-auto">
                <div v-for="(identificador,index) in identificadores" :key="index">
                    <div v-if="identificador.id_tienda != id_tienda" class="card-codigo d-middle px-2 cr-pointer" @click="push(identificador.id_tienda)">
                        <img :src="identificador.usuario.foto" class="obj-cover border br-8" width="66" height="66" />
                        <div class="col pr-0">
                            <p class="f-15 text-general">
                                {{identificador.usuario.nombre}}
                            </p>
                            <p class="f-15 text-general">
                                <span class="f-500"> Registro:</span>  {{identificador.registro | helper-fecha('DD/MM/YYYY')}}
                            </p>
                            <p class="f-15 text-general">
                                <span class="f-500"> Asignación: </span>  {{identificador.asignado | helper-fecha('DD/MM/YYYY')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            url_image: 'https://img.freepik.com/foto-gratis/retrato-joven-sonriente-gafas_171337-4842.jpg?size=626&ext=jpg',
            identificadores : [],
            id_tienda : null,
        }
    },
    methods: {
        toggle(identificadores){
            this.id_tienda = this.$route.params.id_tienda
            this.identificadores = identificadores
            this.$refs.modalLeecherosCodigo.toggle();
        },
        push(id_tienda){
            const ruta = this.$usuario.rol === 1 ? 'admin.tiendas.produccion.informacion' : 'admin-vip.tiendas.produccion.informacion'

            this.$refs.modalLeecherosCodigo.toggle();

            let r = this.$router.resolve({
                name: ruta, // put your route information in
                params :{ id_tienda: id_tienda }, // put your route information in
            });
            window.location.assign(r.href)
        }
    }
}
</script>
<style lang="scss" scoped>
.card-codigo{
    width: 281px;
    height: 82px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
}
</style>
